import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Text, Container, Icon } from "@atoms";
import { PortalImage } from "@molecules";
// import { HomeAudioLinks } from "@molecules";
import { m, useAnimation } from "framer-motion";
import loadable from "@loadable/component";
import t from "@utils/t";
import useLang from "@hooks/useLang";

const AudioPlayerInline = loadable(() =>
  import("@organisms/AudioPlayerInline")
);

const Gram = ({
  image,
  shortName,
  audioState,
  color,
  colorState,
  i,
  single,
  fullName,
  caption,
  scroll,
}) => {
  const lang = useLang();
  const variants = {
    open: {
      opacity: 1,
    },
    closed: {
      opacity: 0,
    },
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [selectAudio, setSelectAudio] = audioState || useState(0);

  const animationControls = useAnimation();
  const swapCards = () => {
    animationControls.start({
      zIndex: selectAudio === i ? 50 : 20,
      transition: { delay: 0.5 },
    });
    animationControls.start({
      x: [0, !i % 2 ? -48 : 48, 0],
      transition: { duration: 1 },
    });
  };

  useEffect(() => {
    swapCards();
  }, [selectAudio]);

  return (
    <m.div
      // eslint-disable-next-line react/no-array-index-key
      key={i}
      className={classNames("relative w-full pb-10", {
        "-mr-6": !i % 2,
        "-ml-6": i % 2,
      })}
      initial={false}
      animate={!single ? animationControls : false}
    >
      <div
        className={classNames("relative sm:max-w-none", {
          "max-w-[10rem]": single,
          "max-w-sm": !single,
        })}
      >
        <PortalImage
          image={image}
          className="relative z-0 w-full"
          portalClasses={classNames({
            "opacity-60": selectAudio !== i,
          })}
          blackBackground
          long
        />
        <div
          className={classNames(
            "absolute top-0 z-20 h-full w-full overflow-hidden rounded-t-full bg-black transition delay-200 duration-300",
            {
              "bg-opacity-0": selectAudio === i,
              "bg-opacity-40": selectAudio !== i,
            }
          )}
        />
      </div>
      {/* Caption animation */}
      <div
        className={classNames("relative mt-4 w-full", {
          "text-right": i % 2,
        })}
      >
        {/* Caption */}
        <m.div
          initial={false}
          className={classNames("absolute top-0 flex w-full", {
            "pointer-events-none": selectAudio !== i,
            "right-0": i % 2,
            "justify-between": single && !scroll,
            "flex-col": !single || (single && scroll),
          })}
          animate={selectAudio === i ? "open" : "closed"}
          variants={variants}
        >
          <div>
            <Text variant="captionHeading">{fullName}</Text>
            <Text variant="caption">{caption}</Text>
          </div>
          {image.attribution && (
            <div>
              <Text variant="caption" dewidow>{`${t(
                "Photo courtest of",
                lang
              )} ${image.attribution}`}</Text>
            </div>
          )}
        </m.div>
        {/* Arrow stuff */}
        <m.button
          initial={false}
          animate={selectAudio !== i ? "open" : "closed"}
          variants={variants}
          type="button"
          className={classNames(
            "absolute top-0 inline-flex w-full items-center gap-2",
            {
              "right-0 flex-row-reverse": i % 2,
              "pointer-events-none": selectAudio === i,
            }
          )}
          onClick={() => {
            setSelectAudio(i);
          }}
        >
          <Icon
            name={i % 2 ? "forwardArrow" : "backwardsArrow"}
            className="w-4"
          />
          <Text variant="button">{shortName}</Text>
        </m.button>
      </div>
    </m.div>
  );
};

const Audiogram = ({
  heading,
  subheading,
  audiograms,
  caption,
  frame,
  color,
  scroll,
  centeredGram,
}) => {
  const [selectAudio, setSelectAudio] = useState(0);
  const colors = ["bg-coral", "bg-viridian"];
  const textStyles = ["text-black", "text-white"];

  return (
    <div
      className={classNames(
        "texture-pattern relative flex min-h-screen w-full items-center overflow-hidden px-4 py-24 transition-colors duration-300",
        colors[color || selectAudio],
        textStyles[color || selectAudio]
      )}
    >
      <Container frame>
        <div
          className={classNames(
            "flex h-full w-full flex-wrap justify-between gap-6 lg:flex-nowrap",
            {
              "items-center": centeredGram,
            }
          )}
        >
          <div
            className={classNames({
              "w-3/5 justify-end": audiograms?.length === 1,
              "w-1/2": audiograms?.length > 1,
            })}
          >
            <Text variant="h2" className={classNames()}>
              {heading}
            </Text>
            <Text variant="h6" className="max-w-sm leading-normal">
              {subheading}
            </Text>
            <div className="mt-14 max-w-xs">
              {typeof window !== "undefined" && (
                <AudioPlayerInline
                  heading={audiograms?.[selectAudio]?.transcriptText}
                  subHeading={audiograms?.[selectAudio]?.transcriptSubtext}
                  transcript={audiograms?.[selectAudio]?.transcript}
                  audio={audiograms?.[selectAudio]?.audio}
                  color={color}
                />
              )}
            </div>
          </div>

          <div
            className={classNames("w-full", {
              "justify-end sm:w-2/5": audiograms?.length === 1,
              "flex w-4/5 sm:w-3/5 md:-mx-12 md:w-1/2": audiograms?.length > 1,
            })}
          >
            {audiograms?.map((audio, i) => {
              return (
                <Gram
                  key={audio?.shortName}
                  {...audio}
                  i={i}
                  audioState={[selectAudio, setSelectAudio]}
                  colorState={[selectAudio, setSelectAudio]}
                  single={audiograms?.length < 2}
                  scroll={scroll}
                />
              );
            })}
          </div>
        </div>
      </Container>
    </div>
  );
};

Audiogram.defaultProps = {
  color: 0,
};

export default Audiogram;
